import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Stack } from '@fluentui/react';
import { ToastContainer } from 'react-toastify';
import { HomePage } from '@containers/HomePage/HomePage';
import { ROUTES } from '@utils/constants';
import '@utils/ReactToastify.css';
import './App.css';

const router = createBrowserRouter([
  {
    path: ROUTES.HOME_PAGE_ROUTE,
    element: <HomePage />,
  },
  {
    path: ROUTES.ABOUT_PAGE_ROUTE,
    element: <div>This is About</div>,
  },
  {
    path: ROUTES.PRICING_PAGE_ROUTE,
    element: <div>This is Pricing</div>,
  },
  {
    path: ROUTES.LOGIN_PAGE_ROUTE,
    element: <div>This is Login</div>,
  },
  {
    path: ROUTES.GETSTARTED_PAGE_ROUTE,
    element: <div>This is Get Started</div>,
  },
  {
    path: ROUTES.PATIENT_SIGNUP_PAGE_ROUTE,
    element: <div>This is Patient Signup</div>,
  },
  {
    path: ROUTES.PROVIDER_SIGNUP_PAGE_ROUTE,
    element: <div>This is Provider Signup</div>,
  },
  {
    path: ROUTES.PLAN_PURCHASE_PAGE_ROUTE,
    element: <div>This is Purchasing Page</div>,
  },
]);

export function App(): React.ReactElement {
  return (
    <Stack className='App'>
      <RouterProvider router={router} />
      <ToastContainer
        position='bottom-right'
        autoClose={1500}
        hideProgressBar={true}
        closeOnClick
        rtl={false}
        theme='light'
        pauseOnHover={false}
      />
    </Stack>
  );
}
