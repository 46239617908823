export const REDUCER_PATH = "api";
export const TAGS = {
    HOME_PAGE: "HomePage",
};
export const ROUTES = {
    HOME_PAGE_ROUTE: "/",
    ABOUT_PAGE_ROUTE: "/about",
    PRICING_PAGE_ROUTE: "/pricing",
    LOGIN_PAGE_ROUTE: "/login",
    GETSTARTED_PAGE_ROUTE: "/getstarted",
    PATIENT_SIGNUP_PAGE_ROUTE: "/patientSignup",
    PROVIDER_SIGNUP_PAGE_ROUTE: "/providerSignup",
    PLAN_PURCHASE_PAGE_ROUTE: "/planPurchase"
}
export const INPUT_VALIDATION_REGEX = {
    email: /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
    // mobile: /^(\+\d{1,3}[- ]?)?\d{10}$/,
    mobile: /^\d{10}$/,
    officeCode: /^[A-Z0-9]{6}$/,
    firstName: /^[A-Za-z .]+$/,
    lastName: /^[A-Za-z .]+$/,
    fullName: /^[A-Z]?[A-Za-z ]+$/,
    displayName: /^[^\-]+$/,
    roleName: /^[A-Z]?[a-z0-9 ]+$/,
    displayOrder: /^[1-9]+[0-9]*$/,
};