export default {
  // Gray
  gray1: '#585858',
  gray2: '#3C3C3C',
  gray3: '#D1D1D1',
  gray4: '#CCD7E0',
  gray5: '#B2BFCA',
  gray6: '#94A2AE',
  gray7: '#616161',
  gray8: '#5E6772',
  gray9: '#4B515B',
  gray10: '#3E424C',
  gray11: '#373A43',
  gray12: '#242424',
  gray13: '#242424',
  gray14: '#f5f5f5',
  gray15: '#687480',
  gray16: '#11100F',
  gray17: '#605E5C',

  // Transparent
  transparent1: 'rgba(211, 228, 237, 0.2)',
  transparent2: 'rgba(167, 191, 217, 0.2)',
  transparent3: 'rgba(145, 177, 205, 0.3)',
  transparent4: 'rgba(123, 155, 181, 0.4)',
  transparent5: 'rgba(97, 127, 152, 0.5)',
  transparent6: 'rgba(46, 70, 90, 0.5)',
  transparent7: 'rgba(23, 46, 69, 0.6)',
  transparent8: 'rgba(25, 38, 54, 0.7)',

  // Blue
  blue1: '#E4F0FA',
  blue2: '#0288D1',
  blue3: '#CBEDFF',
  blue4: '#0288D1',
  blue5: '#00A9DD',
  blue6: '#1A81FF',
  blue7: '#1358F0',
  blue8: '#1142DE',
  blue9: '#1337C9',
  blue10: '#1731B3',
  blue11: '#41A4E5',
  blue12: '#eef3f8',

  // Green
  green1: '#73FF98',
  green2: '#5EC65A',
  green3: '#32F17B',
  green4: '#1BE177',
  green5: '#09CA67',
  green6: '#02AA55',
  green7: '#01803F',
  green8: '#005226',
  green9: '#068576',

  // Orange
  orange1: '#FFDB70',
  orange2: '#FFCE37',
  orange3: '#FFBA00',
  orange4: '#F1A600',
  orange5: '#DC8F00',
  orange6: '#C07600',
  orange7: '#9C5D00',
  orange8: '#754400',

  // Red
  red1: '#EB5757',
  red2: '#FE8091',
  red3: '#C50F1F',
  red4: '#F0363E',
  red5: '#E12024',
  red6: '#CB1213',
  red7: '#B00B0B',
  red8: '#FF7759',

  // Purple
  purple1: '#EDDEFF',
  purple2: '#E9D2FF',
  purple3: '#DBB7FF',
  purple4: '#C28BFF',
  purple5: '#9D51FF',
  purple6: '#7324D6',
  purple7: '#4B01A6',
  purple8: '#340073',
  purple9: '#7160E8',
  purple11: '#4F52B2',

  // Yellow
  yellow1: '#FDC526',
  yellow2: '#EAA300',

  // Brand theme
  brand0: '#E4F0FA', /// primary
  brand1: '#4F52B2', /// Selected list item bg
  brand2: '#d9e1f5', /// Light BG hover
  brand3: '#bac8ed', /// Disabled
  brand4: '#385dc2', /// Custom input border
  brand5: '#3354ae', /// Hover
  brand6: '#2b4793', /// Active

};
