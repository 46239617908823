import { configureStore } from '@reduxjs/toolkit';
import api from './api';

import rootReducer from './reducer';


const appStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      // Adding the api middleware enables caching, invalidation, polling,
      // and other useful features of `rtk-query`.
      .concat(api.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof appStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof appStore.dispatch;
export default appStore;
