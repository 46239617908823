import { mergeStyleSets } from "@fluentui/react";
import { ITheme } from "@theme/foundations";

const getStyles = (theme: ITheme) => mergeStyleSets({
    defaultButton: {
        color: theme.palette.blue2,
        backgroundColor: theme.palette.blue3,
        border: `2px solid ${theme.palette.blue2}`,
        padding: theme.spacing.s5,
        borderRadius: '6px',
        fontSize: theme.fontSizes.xl,
        ':hover': {
            backgroundColor: theme.palette.blue4,
            color: theme.palette.white,
        },
        '@media(max-width: 992px)': {
            fontSize: theme.fontSizes.m,
            padding: theme.spacing.s4,
        }
    },
    secondaryButton: {
        color: theme.palette.blue2,
        backgroundColor: theme.palette.white,
        border: `2px solid ${theme.palette.blue2}`,
        padding: theme.spacing.s5,
        borderRadius: '6px',
        fontSize: theme.fontSizes.xl,
        ':hover': {
            backgroundColor: theme.palette.blue2,
            color: theme.palette.white,
        },
        '@media(max-width: 992px)': {
            fontSize: theme.fontSizes.m,
            padding: theme.spacing.s4,
        }
    }
});

export default getStyles;