import React from 'react';
import { Stack } from '@fluentui/react';
import { useFluentTheme } from '@theme/index';

import { NavBar, SignUp } from 'atoms/index';
// import Footer from 'atoms/Footer';

import getStyles from './HomePage.styles';

export const HomePage = () => {
  const theme = useFluentTheme();
  const { mainWrapper } = getStyles(theme);
  return (
    <Stack>
      <Stack
        styles={mainWrapper}
        tokens={{ childrenGap: 40 }}
        horizontalAlign='center'
        verticalAlign='center'
      >
        <NavBar />
        <SignUp />
        {/* <AboutUs /> */}
        {/* <Reviews /> */}
        {/* <NewsLetter /> */}
      </Stack>
      {/* <Footer /> */}
    </Stack>
  );
};
