import React, { useState } from 'react';
import { Modal, Stack } from '@fluentui/react';
import Input, { InputVariants } from 'atoms/Input';
import { useFluentTheme } from '@theme/index';
import Button, { ButtonVariants } from 'atoms/Button';
import { useSendInquiryMutation } from '@dataLayer/apis';
import Text from 'atoms/Text';
import useToast from '@utils/useToast';
import getStyles from './InquiryForm.styles';

interface IInquiryFormProps {
  onDismiss: () => void;
}

const InquiryForm: React.FunctionComponent<IInquiryFormProps> = ({
  onDismiss,
}: IInquiryFormProps) => {
  const theme = useFluentTheme();
  const { notifySuccess, notifyFailure } = useToast();
  const styles = getStyles(theme);
  const [sendInquiryApi] = useSendInquiryMutation();
  const [message, setMessage] = useState<string>('');
  const defaultInputFields = {
    firstName: {
      name: 'firstName',
      type: 'text',
      value: '',
      placeholder: 'Enter First Name',
      error: false,
      errorMessage: 'Please enter valid name',
      variant: InputVariants.Default,
      required: true,
    },
    lastName: {
      name: 'lastName',
      type: 'text',
      value: '',
      placeholder: 'Enter Last Name',
      error: false,
      errorMessage: 'Please enter valid name',
      variant: InputVariants.Default,
      required: true,
    },
    email: {
      name: 'email',
      type: 'email',
      value: '',
      placeholder: 'Email ID',
      error: false,
      errorMessage: 'Please enter valid email',
      variant: InputVariants.Default,
      required: true,
    },
  };
  //InputFields
  const [inputFields, setInputFields] = useState<any>(defaultInputFields);
  const onInputChange = (_e: any, data: any) => {
    setInputFields({
      ...inputFields,
      [data.name]: {
        ...inputFields[data.name],
        ...data,
      },
    });
  };
  const renderInputFields = () =>
    Object.keys(inputFields).map((input) => {
      const { name, value, type, placeholder, variant, error, errorMessage } = inputFields[input];
      return (
        <Input
          key={`user-page-${name}`}
          name={name}
          placeholder={placeholder}
          value={value}
          variant={variant}
          type={type}
          onChange={onInputChange}
          error={error}
          errorMessage={error ? errorMessage : ''}
          autoComplete='new-password'
        />
      );
    });

  const onSendInquiry = async () => {
    let isValid = true;
    Object.keys(inputFields).forEach((input) => {
      if (!inputFields[input].value) {
        isValid = false;
      }
    });
    if (!isValid) {
      return;
    }
    const response: any = await sendInquiryApi({
      firstName: inputFields.firstName.value,
      lastName: inputFields.lastName.value,
      email: inputFields.email.value,
      message,
    });
    if (response.data) {
      onDismiss();
      notifySuccess(response?.data?.status?.message || 'Inquiry sent successfully');
    } else {
      notifyFailure(response?.data?.status?.message || 'Something went wrong');
    }
  };
  return (
    <Modal isOpen onDismiss={onDismiss}>
      <Stack className={styles.container} tokens={{ childrenGap: theme.spacing.s7 }}>
        <Text variant='largeBold' style={{ textAlign: 'center' }}>
          Details
        </Text>
        {renderInputFields()}
        <textarea
          className={styles.textarea}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <Stack horizontal tokens={{ childrenGap: theme.spacing.s4 }} horizontalAlign='center'>
          <Button variant={ButtonVariants.Secondary} onClick={onDismiss}>
            Cancel
          </Button>
          <Button variant={ButtonVariants.Default} onClick={onSendInquiry}>
            Send
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
export default InquiryForm;
