import { mergeStyleSets } from '@fluentui/react';

const getStyles = (theme) =>
  mergeStyleSets({
    container: {
      width: '100%',
      height: '100%',
      padding: '20px',
      minHeight: '80vh',
    },
    textarea: {
      borderRadius: '4px',
      border: `1px solid ${theme.palette.gray3}`,
      outline: 'none',
      padding: '10px',
    },
  });

export default getStyles;
