import { PartialTheme } from '@fluentui/react';
import colors from '../colors';

const theme: PartialTheme = {
  palette: {
    themePrimary: colors.primary,
    themeDark: colors.primaryHover,
    themeDarker: colors.primaryActive,
    neutralSecondary: colors.textSecondary,
    neutralPrimary: colors.textPrimary,
  },
  semanticColors: {
    errorBackground: colors.error,
    errorText: colors.error,
    bodyBackground: colors.blue1,
  },
};

export default theme;
